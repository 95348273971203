class ButtonTop {
  constructor() {
    const button = document.querySelector(".p-footer1__button1");

    function handleScrollEvent() {
      if (window.scrollY > 120 && !button.classList.contains("-fixed")) {
        button.classList.add("-visible");
      } else if (window.scrollY < 10) {
        button.classList.remove("-visible");
      }
    }

    window.addEventListener("scroll", handleScrollEvent);
  }
}

export default ButtonTop;
