class Light {
  constructor() {
    this.initialize();
  }

  initialize() {
    if (document.querySelectorAll(".c-light1__unit1__item1")) {
      const items = document.querySelectorAll(".c-light1__unit1__item1");

      items.forEach((item) => {
        const delay = Math.random() * 2;
        const duration = Math.random() * 2.5 + 1;
        item.style.animationDelay = `${delay}s`;
        item.style.animationDuration = `${duration}s`;
      });
    }
  }
}

export default Light;
