class Form {
  constructor() {
    if (document.querySelector(".c-formFile1")) {
      this.formFile();
    }
    if (document.querySelector(".c-nav1__form1")) {
      this.yearNav();
    }
  }

  formFile() {
    const el = document.querySelector(".c-formFile1");
    const buttonFile = el.querySelector("button");
    const buttonDelete = el.querySelector(".c-formFile1__delete1");
    const file = el.querySelector(".mw-wp-form_file");
    const input = el.querySelector("input");
    const text = el.querySelector("p");

    if (file) {
      text.textContent = file.querySelector("input").value;
      buttonDelete.style.display = "block";
    }

    buttonFile.addEventListener("click", () => {
      input.click();
    });

    buttonDelete.addEventListener("click", () => {
      text.textContent = "選択されていません";
      buttonDelete.style.display = "none";
      input.value = "";
    });

    input.addEventListener("change", (event) => {
      text.textContent = event.target.files[0].name;
      buttonDelete.style.display = "block";
    });
  }

  yearNav() {
    const el = document.querySelector(".c-nav1__form1");
    const select = el.querySelector(".c-formSelect1 select");

    select.addEventListener("change", (event) => {
      const $val = event.target.value;

      if ($val != "all") {
        window.location.href = "/" + $val + "/";
      } else {
        window.location.href = "/news/";
      }
    });
  }
}

export default Form;
