import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

class TopAnimation {
  constructor() {
    this.loading();
  }

  loading() {
    if (document.querySelector(".p-loading1")) {
      const body = document.querySelector("body");
      const icon = document.querySelector(".p-loading1__logo1__icon1");
      const name = document.querySelector(".p-loading1__logo1__name1");
      const isFirstAccess = sessionStorage.getItem("isFirstAccess");

      gsap.to(icon, {
        opacity: 1,
        duration: 1,
        delay: 1,
        ease: "Power1.easeOut",
      });

      gsap.from(name, {
        duration: 1,
        delay: 1.5,
        top: 0,
        clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
        ease: "Expo.easeOut",
      });

      const start = (delay) => {
        this.openingAnimation(delay);
        this.scrollAnimation();
      };

      if (isFirstAccess === null) {
        // 初回アクセスフラグを保存
        sessionStorage.setItem("isFirstAccess", "true");
        body.classList.add("-loading-start");

        setTimeout(() => {
          if (document.readyState === "complete") {
            body.classList.remove("-loading-start");
            body.classList.add("-loading-end");
            start(0.7);
          } else {
            window.addEventListener("load", () => {
              body.classList.remove("-loading-start");
              body.classList.add("-loading-end");
              start(0.7);
            });
          }
        }, 4000);
      } else {
        body.classList.add("-loading-end");
        start(0);
      }
    }
  }

  openingAnimation(delay) {
    const timeline1 = gsap.timeline({
      scrollTrigger: {
        trigger: ".p-kv1",
        start: "top center",
      },
    });

    timeline1
      .from(".p-kv1__movie1", {
        opacity: 0,
        duration: 1.5,
        delay: delay,
        ease: "Power3.easeOut",
      })
      .from(
        ".p-kv1__catch1__jp1",
        {
          opacity: 0,
          x: -50,
          duration: 1.5,
          ease: "Power3.easeOut",
        },
        "-=1"
      )
      .from(
        ".p-kv1__catch1__en1",
        {
          opacity: 0,
          x: -50,
          duration: 1.5,
          ease: "Power3.easeOut",
        },
        "-=1.25"
      )
      .from(
        ".p-nav1__list1 li, .p-nav1__list2 li",
        {
          opacity: 0,
          y: -50,
          duration: 1,
          stagger: 0.05,
          ease: "Power3.easeOut",
        },
        "<0"
      )
      .from(
        ".p-block1",
        {
          opacity: 0,
          duration: 1,
          ease: "Power3.easeOut",
        },
        "<0"
      )
      .from(
        ".p-kv1__scroll1",
        {
          opacity: 0,
          y: -25,
          duration: 1,
          ease: "Power3.easeOut",
        },
        "<0"
      );
  }

  scrollAnimation() {
    const animation1 = [".p-block5__text1", ".p-block5__image1", ".p-block5__text2", ".p-block6__title1", ".p-block4__title1", ".p-block4__search1"];

    animation1.forEach(function (el, index) {
      gsap.from(el, {
        opacity: 0,
        y: -25,
        duration: 1.5,
        ease: "Expo.easeOut",
        scrollTrigger: {
          trigger: el,
          start: "top+=200 bottom",
        },
      });
    });

    const timeline1 = gsap.timeline({
      scrollTrigger: {
        trigger: ".p-block2",
        start: "top+=300 bottom",
      },
    });

    timeline1
      .from(".p-block2__title1", {
        opacity: 0,
        duration: 1.5,
        ease: "Power3.easeOut",
      })
      .from(
        ".p-block2__text1",
        {
          opacity: 0,
          duration: 1.5,
          ease: "Power3.easeOut",
        },
        "-=1.25"
      )
      .from(
        ".p-block2__image1",
        {
          opacity: 0,
          duration: 1.5,
          ease: "Power3.easeOut",
        },
        "-=1.25"
      )
      .from(
        ".p-block2__button1",
        {
          opacity: 0,
          duration: 1.5,
          ease: "Power3.easeOut",
        },
        "-=1.25"
      );

    const timeline2 = gsap.timeline({
      scrollTrigger: {
        trigger: ".p-block3",
        start: "top+=200 bottom",
      },
    });

    timeline2
      .from(".p-block3__line1", {
        width: "30%",
        duration: 1,
        ease: "Power3.easeOut",
      })
      .from(
        ".p-block3__name1",
        {
          opacity: 0,
          left: "105%",
          duration: 1,
          ease: "Power3.easeOut",
        },
        "<0"
      );

    const timeline3 = gsap.timeline({
      scrollTrigger: {
        trigger: ".p-block5__title1",
        start: "top+=300 bottom",
      },
    });

    timeline3
      .from(".p-block5__title1", {
        opacity: 0,
        duration: 1.5,
        ease: "Power3.easeOut",
      })
      .from(
        ".p-block5__text3",
        {
          opacity: 0,
          duration: 1.5,
          ease: "Power3.easeOut",
        },
        "-=1.25"
      )
      .from(
        ".p-block5__text4",
        {
          opacity: 0,
          duration: 1.5,
          ease: "Power3.easeOut",
        },
        "<0"
      )
      .from(
        ".p-block5__image2",
        {
          opacity: 0,
          duration: 1.5,
          ease: "Power3.easeOut",
        },
        "-=1.25"
      )
      .from(
        ".p-block5__button1",
        {
          opacity: 0,
          duration: 1.5,
          ease: "Power3.easeOut",
        },
        "-=1.25"
      );

    const timeline4 = gsap.timeline({
      scrollTrigger: {
        trigger: ".p-block6__unit1:nth-of-type(1)",
        start: "top+=200 bottom",
      },
    });

    timeline4.from(".p-block6__unit1:nth-of-type(1), .p-block6__unit1:nth-of-type(2), .p-block6__unit1:nth-of-type(3)", {
      opacity: 0,
      y: -25,
      duration: 1.5,
      stagger: 0.25,
      ease: "Power3.easeOut",
    });

    const timeline5 = gsap.timeline({
      scrollTrigger: {
        trigger: ".p-block6__unit1:nth-of-type(4)",
        start: "top+=200 bottom",
      },
    });

    timeline5.from(".p-block6__unit1:nth-of-type(4), .p-block6__unit1:nth-of-type(5), .p-block6__unit1:nth-of-type(6)", {
      opacity: 0,
      y: -25,
      duration: 1.5,
      stagger: 0.25,
      ease: "Power3.easeOut",
    });

    const timeline6 = gsap.timeline({
      scrollTrigger: {
        trigger: ".p-block4__list1 li:nth-of-type(1)",
        start: "top+=200 bottom",
      },
    });

    timeline6.from(".p-block4__list1 li:nth-of-type(1), .p-block4__list1 li:nth-of-type(2)", {
      opacity: 0,
      y: -25,
      duration: 1.5,
      stagger: 0.25,
      ease: "Power3.easeOut",
    });

    const timeline7 = gsap.timeline({
      scrollTrigger: {
        trigger: ".p-block4__list1 li:nth-of-type(3)",
        start: "top+=200 bottom",
      },
    });

    timeline7.from(".p-block4__list1 li:nth-of-type(3), .p-block4__list1 li:nth-of-type(4)", {
      opacity: 0,
      y: -25,
      duration: 1.5,
      stagger: 0.25,
      ease: "Power3.easeOut",
    });
  }
}

export default TopAnimation;
