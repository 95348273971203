import MobileMenu from "./modules/mobileMenu";
import TopAnimation from "./modules/topAnimation";
import ButtonTop from "./modules/buttonTop";
import Light from "./modules/light";
import Form from "./modules/form";

const init = () => {
  new MobileMenu();
  new Light();
  new TopAnimation();
  new ButtonTop();
  new Form();
};

window.addEventListener("DOMContentLoaded", () => {
  init();
});
